import React, { useState } from 'react';

const Share = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Здесь будет логика аутентификации
    console.log('Попытка входа:', { email, password });
  };

  const handleGoogleLogin = () => {
    // Здесь будет логика входа через Google
    console.log('Вход через Google');
  };
  const [showPassword, setShowPassword] = useState(false);


  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-teal-100 p-6">
    <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-lg">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">Вход в аккаунт</h2>
  
      <div className="mb-6">
        <button 
          onClick={handleGoogleLogin}
          className="w-full flex items-center justify-center border border-gray-300 text-gray-700 py-3 rounded-lg hover:bg-blue-50 transition duration-300 space-x-3 shadow-md"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 48 48"
            className="mr-3"
          >
            <path fill="#FFC107" d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"/>
            <path fill="#FF3D00" d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"/>
            <path fill="#4CAF50" d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.925l-6.523 5.025C9.505 39.556 16.227 44 24 44z"/>
            <path fill="#1976D2" d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.801 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"/>
          </svg>
          Войти через Google
        </button>
      </div>
  
      <div className="mt-8 text-center">
        <p className="text-sm text-gray-600">
          Или
        </p>
      </div>
  
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
            Email
          </label>
          <input 
            type="email" 
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            placeholder="Введите ваш email"
          />
        </div>
  
        <div className="relative">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
            Пароль
          </label>
          <input 
            type={showPassword ? "text" : "password"} // Меняет тип поля в зависимости от состояния
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            placeholder="Введите пароль"
          />
          
        </div>
  
        <div className="flex items-center justify-between">
        
          <div className="flex items-center">
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)} // Переключает состояние показа пароля
            className=" right-4 top-1/2 transform -translate-y-1/2 text-blue-600"
          >Показать / скрыть
            {showPassword ? ( 
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye ">
            
                <path d="M1 12s3-7 11-7 11 7 11 7-3 7-11 7-11-7-11-7z"></path>
                <path d="M12 9c2.5 0 4.5 1.5 4.5 3s-2 3-4.5 3-4.5-1.5-4.5-3 2-3 4.5-3z"></path>
              </svg>
             
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off">
                <path d="M17 11c.15 1-.15 2-1 2s-1.85-1-1.7-2.15"></path>
                <path d="M22 12s-3-7-10-7S2 12 2 12s3 7 10 7 10-7 10-7z"></path>
              </svg>
            )}
          </button>
            {/* <input 
              type="checkbox" 
              id="remember" 
              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            /> */}
            
            {/* <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">
              Запомнить меня
            </label> */}
          </div>
          <div>
            <a href="#" className="text-sm text-blue-600 hover:underline">
              Забыли пароль?
            </a>
          </div>
        </div>
  
        <div>
          <button 
            type="submit" 
            className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Войти
          </button>
        </div>
      </form>
  
      <div className="mt-8 text-center">
        <p className="text-sm text-gray-600">
          Нет аккаунта? {' '}
          <a href="#" className="text-blue-600 hover:underline">
            Регистрация
          </a>
        </p>
      </div>
    </div>
  </div>
  
  
  );
};

export default Share;

// <div class="row ">
//         <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-teal-100 p-6">
//     <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-lg">
//       <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">Регистрация</h2>
  
//       <div className="mb-6">
//         <button 
//           onClick={handleGoogleLogin}
//           className="w-full flex items-center justify-center border border-gray-300 text-gray-700 py-3 rounded-lg hover:bg-blue-50 transition duration-300 space-x-3 shadow-md"
//         >
//           <svg 
//             xmlns="http://www.w3.org/2000/svg" 
//             width="24" 
//             height="24" 
//             viewBox="0 0 48 48"
//             className="mr-3"
//           >
//             <path fill="#FFC107" d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"/>
//             <path fill="#FF3D00" d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"/>
//             <path fill="#4CAF50" d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.925l-6.523 5.025C9.505 39.556 16.227 44 24 44z"/>
//             <path fill="#1976D2" d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.801 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"/>
//           </svg>
//           Войти через Google
//         </button>
//       </div>
  
//       <div className="mt-8 text-center">
//         <p className="text-sm text-gray-600">
//           Или
//         </p>
//       </div>
  
//       <form onSubmit={handleSubmit} className="space-y-6">
//         <div>
//           <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
//             Email
//           </label>
//           <input 
//             type="login" 
//             id="login"
//             value={login}
//             onChange={(event) => setLogin(event.target.value)}
//             // onChange={(e) => setEmail(e.target.value)}
//             required
//             className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
//             placeholder="Введите ваш email"
//           />
//         </div>
  
//         <div className="relative">
//           <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
//             Пароль
//           </label>
//           <input 
//             type={showPassword ? "text" : "password"} // Меняет тип поля в зависимости от состояния
//             id="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//             className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
//             placeholder="Введите пароль"
//           />
          
          
//         </div>
  
//         <div className="flex items-center justify-between">
        
//           <div className="flex items-center">
//           <button
//             type="button"
//             onClick={() => setShowPassword(!showPassword)} // Переключает состояние показа пароля
//             className=" right-4 top-1/2 transform -translate-y-1/2 text-blue-600"
//           >Показать / скрыть
//             {showPassword ? ( 
//               <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye ">
            
//                 <path d="M1 12s3-7 11-7 11 7 11 7-3 7-11 7-11-7-11-7z"></path>
//                 <path d="M12 9c2.5 0 4.5 1.5 4.5 3s-2 3-4.5 3-4.5-1.5-4.5-3 2-3 4.5-3z"></path>
//               </svg>
             
//             ) : (
//               <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off">
//                 <path d="M17 11c.15 1-.15 2-1 2s-1.85-1-1.7-2.15"></path>
//                 <path d="M22 12s-3-7-10-7S2 12 2 12s3 7 10 7 10-7 10-7z"></path>
//               </svg>
//             )}
//           </button>
//             {/* <input 
//               type="checkbox" 
//               id="remember" 
//               className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
//             /> */}
            
//             {/* <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">
//               Запомнить меня
//             </label> */}
//           </div>
//           {/* <div>
//             <a className="text-sm text-blue-600 hover:underline">
//               Забыли пароль?
//             </a>
//           </div> */}
//         </div>
  
//         <div>
//           <button 
//             onClick={registerWithEmailPassword}
//             type="submit" 
//             className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
//           >
//             Зарегистрироваться
//           </button>
//         </div>
//       </form>
  
//       <div className="mt-8 text-center">
//         <p className="text-sm text-gray-600">
//           У вас есть акаунт? {' '}
//           <a href="#" className="text-blue-600 hover:underline">
//               Вход
//           </a>
//         </p>
//       </div>
//     </div>
//   </div>
         
       

          

        

//         </div>