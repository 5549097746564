import './App.css';
import React,{Component} from 'react';
import {BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import 'moment/locale/ru';
import Main from './components/main';
import Nav from './components/navbar';
import Footer from './components/footer';
import Post from './components/post';
import Delivery from './components/delivery';
import Userinfo from './components/userinfo';
import Korzina from './components/korzina';
import Newpost from './components/new_post';
import Mainpost from './components/mainpost';
import Postcard from './components/postcard';
import Mainpost2 from './components/mainpost2';
import Post2 from './components/post2';
import Post3 from './components/post3';
import New_post from './components/new_post';
import Smsmodal from './components/smsmodal';
import Share from './components/share';
import Mainsms from './components/mainsms';
import Menuhover from './components/menuhover';
import Transport from './components/transport';
import Upload1 from './components/upload';
import Registr from './components/registr';
import Vhod from './components/vhod';
import Podcategory from './components/podcategory';
import Nedvij from './components/nedvij';
import Result1 from './components/result';
import Filter_city from './components/filter_city';
import Put_ad from './components/put_ad';
import Post_lk from './components/post_lk';
import Put_user from './components/put_user';
import User from './components/user';
import Registrmob from './components/registrmob';
import Put_user2 from './components/put_user2';
import Registr2 from './components/registr2';
import Newpassword from './components/newpassword';
import Panel from './components/panel';
import Admin from './components/admin';
import Kvartiry from './components/kvartiry';
import Doma from './components/doma';
import Transport_ad from './components/transport_ad';
import Realty from './components/realty';
import Uslugi from './components/uslugi';
import Electronics from './components/electronics';
import Dom_i_sad from './components/dom_i_sad';
import Rabota from './components/rabota';
import Moda_style from './components/moda_style';
import Detskiy_mir from './components/detskiy_mir';
import Hobbi_sport_otdyh from './components/hobbi_sport_otdyh';
import Animals from './components/animals';
import Darom from './components/darom';
import Ohota_rybalka from './components/ohota_rybalka';
import Podarki from './components/podarki';
import Zapchasti from './components/zapchasti';
import Biznes from './components/biznes';
import Lichnye_veshi from './components/lichnye_veshi';
import Odejda_new from './components/odejda_new';
import Obuvi from './components/obuvi';
import Medical from './components/medical';
import Nav2 from './components/navbar2';
import Users_ad from './components/users_ad';
import Ozon_park from './components/ozon_park';
import Ozon_park2 from './components/ozon_park2';
import Post_aktiv from './components/post_aktiv';
import Post_deak from './components/post_deak';
import Post_moder from './components/post_moder';
import Upload_zapchasti from './components/upload_zapchasti';
import Upload_odejda from './components/upload_odejda';
import Upload_obuvi from './components/upload_obuvi';
import Inform from './components/inform';
import Password_new from './components/password_new';
import New_main from './components/new_main';
import Update_ad from './components/update_ad';
import Info from './components/info';
import Kvartirant_alam from './components/kvartirant_alam';
import Komnata_berem from './components/komnata_berem';
import Meimankana from './components/meimankana';
import Kvartira_berilet from './components/kvartira_berilet';
import Agenstvo from './components/agenstvo';
import Komnata_izdeim from './components/komnata_izdeim';
import Koika from './components/koika';
import Jumush_berilet from './components/jumush_berilet';
import Jumush_izdeim from './components/jumush_izdeim';
import Taksopark from './components/taksopark';
import Arenda_avto from './components/arenda_avto';
import Migranttyk_konsultasia from './components/migranttyk_konsulatsia';
import Upload_image from './components/upload_image';
import Mainsms2 from './components/mainsms2';
import Post2_like from './components/post2_like';
import Catalog from './components/catalog';
import Privacy from './components/privacy';
import Registr3 from './components/registr3';






function App() {
  return (
    <>
    

      <Router>
      <Routes>
      <Route path = '/' element ={<Main/>}/>
      <Route path = '/navbar' element ={<Nav/>}/>
      <Route path = '/navbar2' element ={<Nav2/>}/>
      <Route path = '/footer' element ={<Footer/>}/>
      <Route path = '/post/:id' element ={<Post/>}/>
      <Route path = '/delivery' element ={<Delivery/>}/>
      <Route path = '/userinfo/:id' element ={<Userinfo/>}/>
      <Route path = '/info/:id' element ={<Info/>}/>
      <Route path = '/inform/:num' element ={<Inform/>}/>
      <Route path = '/privacy' element ={<Privacy/>}/>
      <Route path = '/users_ad/:id' element ={<Users_ad/>}/>
      <Route path = '/korzina' element ={<Korzina/>}/>
      <Route path = '/newpost' element ={<Newpost/>}/>
      <Route path = '/mainpost' element ={<Mainpost/>}/>
      <Route path = '/vhod' element ={<Vhod/>}/>
      <Route path = '/postcard' element ={<Postcard/>}/>
      <Route path = '/mainpost2' element ={< Mainpost2/>}/>
      <Route path = '/post2' element ={<Post2/>}/>
      <Route path = '/catalog' element ={<Catalog/>}/>
      <Route path = '/post2_like' element ={<Post2_like/>}/>
      <Route path = '/post_aktiv' element ={<Post_aktiv/>}/>
      <Route path = '/post_deak' element ={<Post_deak/>}/>
      <Route path = '/post_moder' element ={<Post_moder/>}/>
      <Route path = '/post3' element ={<Post3/>}/>
      <Route path = '/post_lk' element ={<Post_lk/>}/>
           <Route path = '/registr3/:email' element ={<Registr3/>}/>
      <Route path = '/registr' element ={<Registr/>}/>
      <Route path = '/password_new' element ={<Password_new/>}/>
      <Route path = '/registr2' element ={<Registr2/>}/>
      <Route path = '/registrmob' element ={<Registrmob/>}/>
      <Route path = '/user/:id' element ={<User/>}/>
      <Route path = '/new_post' element ={<New_post/>}/>
      <Route path = '/newpost' element ={<Newpost/>}/>
      <Route path = '/smsmodal' element ={<Smsmodal/>}/>
      <Route path = '/share' element ={<Share/>}/>
      <Route path = '/mainsms/:id' element ={<Mainsms/>}/>
      <Route path = '/mainsms2/:id' element ={<Mainsms2/>}/>
      <Route path = '/menuhover' element ={<Menuhover/>}/>
      <Route path = '/transport' element ={<Transport/>}/>
      <Route path = '/upload1/:id' element ={<Upload1/>}/>
      <Route path = '/upload_zapchasti' element ={<Upload_zapchasti/>}/>
      <Route path = '/upload_odejda' element ={<Upload_odejda/>}/>
      <Route path = '/upload_obuvi' element ={<Upload_obuvi/>}/>
      <Route path = '/realty' element ={<Realty/>}/>
      <Route path = '/kvartirant_alam' element ={<Kvartirant_alam/>}/>
      <Route path = '/komnata_berem' element ={<Komnata_berem/>}/>
      <Route path = '/meimankana' element ={<Meimankana/>}/>
      <Route path = '/agenstvo' element ={<Agenstvo/>}/>
      <Route path = '/komnata_izdeim' element ={<Komnata_izdeim/>}/>
      <Route path = '/koika' element ={<Koika/>}/>
      <Route path = '/jumush_berilet' element ={<Jumush_berilet/>}/>
      <Route path = '/jumush_izdeim' element ={<Jumush_izdeim/>}/>
      <Route path = '/taksopark' element ={<Taksopark/>}/>
      <Route path = '/arenda_avto' element ={<Arenda_avto/>}/>
      <Route path = '/migranttyk_konsultasia' element ={<Migranttyk_konsultasia/>}/>
      <Route path = '/kvartira_berilet' element ={<Kvartira_berilet/>}/>
      <Route path = '/podcategory/:name/:id' element ={<Podcategory/>}/>
      <Route path = '/nedvij/:id' element ={<Nedvij/>}/>
      <Route path = '/new_main' element ={<New_main/>}/>
      <Route path = '/result' element ={<Result1/>}/>
      <Route path = '/filter_city' element ={<Filter_city/>}/>
      <Route path = '/put_ad/:id' element ={<Put_ad/>}/>
      <Route path = '/update_ad/:id' element ={<Update_ad/>}/>
      <Route path = '/upload_image/:id' element ={<Upload_image/>}/>
      <Route path = '/put_user' element ={<Put_user/>}/>
      <Route path = '/put_user2' element ={<Put_user2/>}/>
      <Route path = '/newpassword' element ={<Newpassword/>}/>
      <Route path = '/panel' element ={<Panel/>}/>
      <Route path = '/panel/admin' element ={<Admin/>}/>
      <Route path = '/kvartiry/:value' element ={<Kvartiry/>}/>
      <Route path = '/doma' element ={<Doma/>}/>
      <Route path = '/transport_ad' element ={<Transport_ad/>}/>
      <Route path = '/uslugi' element ={<Uslugi/>}/>
      <Route path = '/electronics' element ={<Electronics/>}/> 
      <Route path = '/dom_i_sad' element ={<Dom_i_sad/>}/>
      <Route path = '/rabota' element ={<Rabota/>}/>
      <Route path = '/moda_style' element ={<Moda_style/>}/>
      <Route path = '/detskiy_mir' element ={<Detskiy_mir/>}/> 
      <Route path = '/hobbi_sport_otdyh' element ={<Hobbi_sport_otdyh/>}/>   
      <Route path = '/animals' element ={<Animals/>}/>
      <Route path = '/darom' element ={<Darom/>}/>
      <Route path = '/ohota_rybalka' element ={<Ohota_rybalka/>}/>
      <Route path = '/podarki' element ={<Podarki/>}/>
      <Route path = '/zapchasti' element ={<Zapchasti/>}/>
      <Route path = '/biznes' element ={<Biznes/>}/>
      <Route path = '/lichnye_veshi' element ={<Lichnye_veshi/>}/> 
      <Route path = '/odejda_new' element ={<Odejda_new/>}/>
      <Route path = '/obuvi' element ={<Obuvi/>}/>
      <Route path = '/medical' element ={<Medical/>}/>
      <Route path = '/ozon_park' element ={<Ozon_park/>}/>
      <Route path = '/ozon_park2' element ={<Ozon_park2/>}/>
      </Routes>
      </Router>

    
    
    
    </>
  );
}

export default App;
